@import "styles/mixins/index";

.rewards-card-alignment {
  padding: 60px 0 120px 0;

  @include breakpoint("max-sm") {
    padding: 60px 0;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 60px;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .grid-items {
      grid-column: span 4;
      background-color: var(--bg-white);
      border: 1px solid var(--border-color-3);
      padding: 25px;
      border-radius: 6px;
      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      box-shadow: rgb(136 165 191 / 48%) -1px 1px 19px 1px,
        rgb(255 255 255 / 80%) -6px -2px 16px 0px;

      @include breakpoint("max-md") {
        grid-column: auto;
      }

      .total-counter {
        .counter-value {
          @include text-sm;
          color: red;
          display: block;
          @include text-center;
        }
      }

      .card-sub-head-alignment {
        height: 282px;

        @include breakpoint("max-sm") {
          height: auto;
        }

        h2 {
          font-size: 17px;
          line-height: 28px;
          font-family: var(--heading-font-family), var(--default-font-family);
          font-weight: 500;
          color: var(--text-current);
          text-align: center;
          margin: 0 0 20px 0;
          text-align: justify;
          -webkit-line-clamp: 2;
          min-height: 56px;
        }
      }
      .modal__btn {
        display: flex;
        justify-content: flex-end;
        a {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          font-family: var(--text-font-family), var(--default-font-family);
          font-size: 17px;
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;

          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);
          }
        }
      }

      .progrees-bar-label {
        @include flex-center;
        padding: 0 0 10px 0;
        justify-content: space-between;

        h6 {
          @include heading-6;
          margin: 0;
          color: var(--text-current);
        }
      }

      .round-progress-alignment {
        @include flex-center;
        justify-content: center;
        margin: 0 0 5px 0;

        div:last-child {
          margin: 0;
        }

        .round-active {
          background-color: var(--secondary-color);
        }

        div {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          background-color: #d1d9e1;
          border-radius: 50%;
          @include flex-center;
          justify-content: center;
          @include pointer;
        }
      }

      .progrees-bar {
        width: 100%;
        height: 20px;
        margin: 0 0 10px 0;
        border-radius: 999px;
        background-color: #d1d9e1;
        position: relative;

        .progrees-bar-active {
          position: absolute;
          top: 0;
          left: 0;
          background-color: var(--secondary-color);
          width: 20%;
          height: 100%;
          border-radius: 9999px;

          span {
            margin: 0 auto;
            color: #fff;
          }
        }
      }

      .reward-image-center-alignment {
        @include flex-center;
        justify-content: center;
        padding: 0 0 20px 0;

        img {
          min-width: 128px;
          max-width: 128px;
          min-height: 128px;
          max-height: 128px;
          object-fit: cover;
        }
      }

      .button-right-side-alignment {
        @include flex-center;
        justify-content: flex-end;
        padding: 0 0 20px 0;

        button {
          padding: 5px 16px;
          background-color: var(--bg-primary-light);
          color: var(--primary-color);
          @include text-sm-normal;
          border: none;
          border-radius: 3px;
          justify-content: center;
          @include flex-center;
          @include font-medium;

          span {
            padding-left: 6px;
          }
        }
      }

      .rewards-border {
        border: 1px solid var(--border-color-3);
        border-radius: 3px;

        .rewards-card-body {
          padding: 20px 15px 20px 15px;
          margin: 0 auto;

          table {
            width: 100%;
            border-collapse: collapse;

            tr:first-child {
              border-bottom: 1px solid var(--border-color-3);
            }

            tr {
              border-bottom: none;

              th {
                @include text-md-semi-bold;
                font-size: 16px;
                color: var(--text-current);
                padding: 12px 6px;
                text-align: left;
              }

              td {
                @include text-sm;
                @include font-normal;
                color: var(--text-current);
                padding: 4px 6px;
                text-align: left;
              }

              .upc-table {
                float: left;
              }
            }
          }
        }

        .rewards-header {
          background-image: url("../../../assets/images/table-heading-banner.png");
          background-size: cover;
          width: 100%;
          height: 50px;
          background-size: cover;
          @include flex-center;
          justify-content: center;

          h3 {
            @include heading-6;
            margin: 0;
            color: var(--bg-white);
            @include text-center;
          }
        }
      }

      p {
        @include text-sm-normal;
        line-height: 24px;
        margin: 0 0 20px 0;
        @include text-center;
        color: var(--text-current);
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: justify;
        min-height: 96px;
      }

      h2 {
        @include heading-4;
        color: var(--text-current);
        @include text-center;
        margin: 0 0 20px 0;
        text-align: justify;
        -webkit-line-clamp: 2;

        span {
          color: var(--secondary-color);
        }
      }
    }
  }
}
